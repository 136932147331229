<template>
  <div>
    <a-modal
      :visible="visible"
      title="新增"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="公司">
          <a-input
            v-decorator="[
              'company',
              {
                rules: [
                  { required: true, message: '请输入公司' },
                  { max: 32, message: '超出最大长度(32)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="用户名">
          <a-input
            v-decorator="[
              'username',
              {
                rules: [
                  { required: true, message: '请输入用户名' },
                  { max: 32, message: '超出最大长度(32)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="系统">
          <a-select
            v-decorator="[
              'system',
              {
                rules: [{ required: true, message: '请选择系统' }],
              },
            ]"
            :allowClear="true"
            style="width: 100%"
            @change="onChangeSystem"
          >
            <a-select-option key="test_wms" value="test_wms">WMS 测试环境</a-select-option>
            <a-select-option key="test_erp" value="test_erp">ERP 测试环境</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="账号数量">
          <a-input-number
            v-decorator="[
              'user_count',
              {
                rules: [{ required: true, message: '请输入账号数量' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="续费周期">
          <a-select v-decorator="['renewal_period']" :allowClear="true" style="width: 100%" @change="onChangeRenewalPeriod">
            <a-select-option key="按月" value="按月">按月</a-select-option>
            <a-select-option key="按季度" value="按季度">按季度</a-select-option>
            <a-select-option key="按年" value="按年">按年</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="到期日期">
          <a-date-picker
            v-decorator="[
              'expiry_date',
              {
                rules: [{ required: true, message: '请输入到期日期' }],
              },
            ]"
            valueFormat="YYYY-MM-DD"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="激活状态">
          <a-select v-decorator="['is_active', { initialValue: 'T' }]" style="width: 100%">
            <a-select-option key="T" value="T">激活</a-select-option>
            <a-select-option key="F" value="F">冻结</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>

      <div style="color: rgb(255, 77, 79); text-align: center">默认初始密码为: 123456, 登录后请修改密码</div>
    </a-modal>
  </div>
</template>

<script>
import { accountCreate } from "@/apis/data";
import moment from "moment";

export default {
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          accountCreate(values)
            .then((data) => {
              this.$emit("create", data);
              this.$message.success("新增成功");
              this.handleCancel();
            })
            .catch((error) => {
              const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                return acc;
              }, {});
              this.dataForm.setFields(errorFields);
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
    onChangeRenewalPeriod(value) {
      if (value === "按月") {
        this.dataForm.setFieldsValue({
          expiry_date: moment().add(30, "days").format("YYYY-MM-DD"),
        });
      } else if (value === "按季度") {
        this.dataForm.setFieldsValue({
          expiry_date: moment().add(90, "days").format("YYYY-MM-DD"),
        });
      } else if (value === "按年") {
        this.dataForm.setFieldsValue({
          expiry_date: moment().add(365, "days").format("YYYY-MM-DD"),
        });
      }
    },
    onChangeSystem(value) {
      if (value === "test_wms") {
        this.dataForm.setFieldsValue({ user_count: 10 });
      } else if (value === "test_erp") {
        this.dataForm.setFieldsValue({ user_count: 1 });
      }
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
